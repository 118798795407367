import React, { useState, useEffect } from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

import { FormattedMessage, FormattedNumber } from "gatsby-plugin-intl"

import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa"

import { isBrowser } from "../../../context/ApolloContext"
import { withStoreContext } from "../../../context/StoreContext"
import { NewsletterSubscribe } from "../../../utils/NewsletterSubscriptionHelper"

import {
  FooterWrapper,
  Container,
  TopDiv,
  BotDiv,
  Heading,
  LeftRight,
  Input,
  Button,
  EmailWrapper,
  Error,
  Success,
  Message,
  BotListWrapper,
  ListElementWrapper,
  ListElement,
  ButtonContact,
  BackToTop,
  TopArrow,
  EmailDiv,
  InSideEmail,
  SubscribeTextWrapper,
  BottomText,
  BottomTextWrapper,
  HeadingTitle,
} from "./styled"

import {
  isLoadedScript,
  formSubmitEventScript,
} from "../../../utils/additionalScriptHelpers"

import styled from "@emotion/styled"

const Footer = ({ storeContext: { checkout, addDiscount }, data, intl }) => {
  const [subscribeEmail, setsubscribeEmail] = useState("")
  const [subscribeName, setsubscribeName] = useState("")
  const [error, seterror] = useState(null)
  const [success, setsuccess] = useState(null)
  const [infoMessage, setInfoMessage] = useState(null)
  const [showScroll, setShowScroll] = useState(false)
  const [NewsTrans, setNewsTrans] = useState({})

  const discountCode =
    process.env.GATSBY_ACTIVE_ENV === "gbp"
      ? "WELCOME5"
      : process.env.GATSBY_ACTIVE_ENV === "eur"
      ? "FLASH"
      : ""

  const discountAmount = process.env.GATSBY_ACTIVE_ENV === "gbp" ? 5 : 10

  const newsletterData = useStaticQuery(graphql`
    query NewsQuery {
      allDirectusNewsletterTranslation {
        nodes {
          title
          subtitle
          text
          logo {
            data {
              url
            }
          }
          name_label_text
          email_label_text
          button_text
          email_error_text
          require_error_text
          thanks_text
          email_exist_text
          invalid_email_text
          language
          subs_text
          name_placeholder
          email_placeholder
          conditions_text
        }
      }
    }
  `)

  function Translation() {
    if (
      newsletterData &&
      newsletterData.allDirectusNewsletterTranslation.nodes.length > 0
    ) {
      let node
      newsletterData.allDirectusNewsletterTranslation.nodes.forEach(
        (item, index) => {
          if (item.language === intl.locale) {
            node = index
          }
        }
      )
      if (node >= 0) {
        setNewsTrans(
          newsletterData.allDirectusNewsletterTranslation.nodes[node]
        )
      }
    }
  }

  const applyWelcomeDiscount = () => {
    if (discountCode) {
      if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
        addDiscount(checkout.id, discountCode)
      }
      // if (process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr") {
      //   addDiscount(checkout.id, discountCode)
      // }
      setInfoMessage(
        <FormattedMessage
          id="welcome_discount_applied"
          values={{
            amount: (
              <FormattedNumber
                value={discountAmount}
                key="discountAmount"
                style="currency"
                currency={process.env.GATSBY_CURRENCY_TYPE}
                currencyDisplay="narrowSymbol"
                maximumFractionDigits={0}
              />
            ),
            strong: (...chunks) => <strong>{chunks}</strong>,
            p: (...chunks) => <p>{chunks}</p>,
          }}
        />
      )
    }
  }

  const NewsletterSubmit = async () => {
    NewsletterSubscribe({
      locale: intl.locale,
      subscribeEmail: subscribeEmail,
      // subscribeName: subscribeName,
      assignAbTestGroup: true,
    })
      .then(response => {
        console.log("NewsletterSubmit", response)
        if (response.success) {
          switch (response.success) {
            case "succesfully_subscribed":
              console.log("succesfully_subscribed")
              formSubmitEventScript("newsletter_submit", intl.locale)
              setsuccess(
                intl.formatMessage({ id: "newsletter_subscribe_success" })
              )
              break
            case "already_subscribed":
              console.log("already_subscribed")
              seterror(
                intl.formatMessage({ id: "newsletter_already_subscribed" })
              )
              setsuccess(null)
              break
            default:
              setsuccess(
                intl.formatMessage({ id: "newsletter_subscribe_success" })
              )
              break
          }
          if (
            (process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr") ||
            (process.env.GATSBY_ACTIVE_ENV === "gbp" && intl.locale === "en")
          ) {
            applyWelcomeDiscount()
          }
        }
      })
      .catch(response => {
        console.log("error", response)
        switch (response.error) {
          case "invalid_email":
            console.log("invalid_email")
            seterror(
              intl.formatMessage({
                id: "newsletter_subscribe_invalid_email_error",
              })
            )
            setsuccess(null)
            break
          case "internal_error":
            seterror(
              intl.formatMessage({ id: "newsletter_subscribe_internal_error" })
            )
            setsuccess(null)
            break
          case "no_email_provided":
            seterror(
              intl.formatMessage({
                id: "newsletter_subscribe_no_email_provided_error",
              })
            )
            setsuccess(null)
            break
        }
      })
  }

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  if (isBrowser) {
    window.addEventListener("scroll", checkScrollTop)
  }

  const wheelioScript = () => {
    window.shopID = "b8021a44-5488-4190-7b1d-08dad6cd4e6a"
    let srcUrl =
      "https://wheelioapp.azureedge.net/app/index.min.js?v=" +
      new Date().getTime()

    if (!isLoadedScript(srcUrl)) {
      ;(function() {
        var script = document.createElement("script")
        script.async = true
        script.src = srcUrl
        document.body.appendChild(script)
      })()
    }
  }

  useEffect(() => {
    Translation()
    // if (
    //   (process.env.GATSBY_ACTIVE_ENV === "eur" ||
    //     process.env.GATSBY_ACTIVE_ENV === "general") &&
    //   intl.locale === "fr"
    // ) {
    //   isBrowser && wheelioScript()
    // }
  }, [intl.locale])

  return (
    <FooterWrapper id="footer">
      <TopDiv>
        <Container>
          <LeftRight>
            {process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr" ? (
              <TopBox>
                <NewsletterTitle lang={intl.locale}>
                  {intl.locale === "fr"
                    ? "tentez de gagner"
                    : "Subscribe for a chance to win:"}
                </NewsletterTitle>
                <NewsletterText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "footer_newsletter_subscribe_message",
                    }),
                  }}
                />
              </TopBox>
            ) : process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
              <HeadingTitle>
                {intl.locale === "fr"
                  ? "Inscrivez-vous à notre newsletter pour nos conseils et nos offres"
                  : "SUBSCRIBE TO OUR NEWSLETTER FOR £5 OFF YOUR FIRST ORDER"}
              </HeadingTitle>
            ) : (
              <Heading>{data.newsletter_heading + ":"}</Heading>
            )}

            <EmailWrapper>
              <InSideEmail>
                {/* <SubscribeTextWrapper>
                  <FormattedMessage
                    id="footer_newsletter_subscribe_message"
                    defaultMessage={NewsTrans.subs_text}
                    values={{
                      amount: (
                        <FormattedNumber
                          value={discountAmount}
                          key="discountAmount"
                          style="currency"
                          currency={process.env.GATSBY_CURRENCY_TYPE}
                          currencyDisplay="narrowSymbol"
                          maximumFractionDigits={0}
                        />
                      ),
                      strong: (...chunks) => <strong>{chunks}</strong>,
                      p: (...chunks) => <p>{chunks}</p>,
                    }}
                  />
                </SubscribeTextWrapper> */}
                {/* <SubscribeTextWrapper
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "footer_newsletter_subscribe_message",
                    }),
                  }}
                /> */}
                <EmailDiv>
                  {/* <Input
                    placeholder={NewsTrans.name_placeholder}
                    type="name"
                    name="subscribe_name"
                    onChange={e => setsubscribeName(e.target.value)}
                  /> */}
                  <Input
                    placeholder={NewsTrans.email_placeholder}
                    type="email"
                    name="subscribe_email"
                    onChange={e => setsubscribeEmail(e.target.value)}
                  />
                </EmailDiv>
                {/* <BottomText to="/jeuconcours/">
                  {NewsTrans.conditions_text || ""}
                </BottomText> */}
              </InSideEmail>
              <Button onClick={NewsletterSubmit}>OK</Button>
            </EmailWrapper>

            {error && <Error>{error}</Error>}
            {!infoMessage && success && (
              <Success dangerouslySetInnerHTML={{ __html: success }} />
            )}
            {success && infoMessage && <Message>{infoMessage}</Message>}
            {(intl.locale === "fr" || intl.locale === "en") && (
              <BottomTextWrapper>
                <FormattedMessage
                  id="newsletter_terms_and_conditions_message"
                  values={{
                    gdpr_link: (
                      <a
                        href={intl.formatMessage({ id: "gdpr_link.url" })}
                        target="_blank"
                      >
                        {intl.formatMessage({
                          id: "gdpr_link.description",
                        })}
                      </a>
                    ),
                    terms_and_condition_link: (
                      <a
                        href={intl.formatMessage({
                          id: "terms_and_condition_link.url",
                        })}
                        target="_blank"
                      >
                        {intl.formatMessage({
                          id: "terms_and_condition_link.description",
                        })}
                      </a>
                    ),
                  }}
                />
              </BottomTextWrapper>
            )}
          </LeftRight>
          <LeftRight>
            <Heading>{data.follow_us_heading + ":"}</Heading>
            <EmailWrapper icons>
              <a
                target={"blank"}
                href={
                  process.env &&
                  process.env.GATSBY_ACTIVE_ENV === "gbp" &&
                  data.gbp_facebook
                    ? data.gbp_facebook
                    : data.facebook
                }
              >
                <FaFacebookF
                  style={{ marginRight: 7, marginTop: 11, cursor: "pointer" }}
                  color="white"
                  size={15}
                />
              </a>
              <a target={"blank"} href={data.instagram}>
                <FaInstagram
                  style={{ marginRight: 7, marginTop: 11, cursor: "pointer" }}
                  color="white"
                  size={16}
                />
              </a>
              <a target={"blank"} href={data.linkedin}>
                <FaLinkedin
                  style={{ marginRight: 7, marginTop: 11, cursor: "pointer" }}
                  color="white"
                  size={16}
                />
              </a>
              <a target={"blank"} href={data.twitter}>
                <FaTwitter
                  style={{ marginRight: 7, marginTop: 11, cursor: "pointer" }}
                  color="white"
                  size={16}
                />
              </a>
              <a target={"blank"} href={data.youtube}>
                <FaYoutube
                  style={{ marginRight: 7, marginTop: 11, cursor: "pointer" }}
                  color="white"
                  size={16}
                />
              </a>
            </EmailWrapper>
          </LeftRight>
        </Container>
      </TopDiv>
      <BotDiv>
        <Container>
          {process.env &&
          data.about_us_menu &&
          (process.env.GATSBY_ACTIVE_ENV === "gbp" ||
            (process.env.GATSBY_ACTIVE_ENV === "eur" &&
              intl.locale === "fr")) ? (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.about_us_menu.name}
              </Heading>
              {data.about_us_menu.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : item.link.indexOf("mailto") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
            </BotListWrapper>
          ) : (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.about_us.name}
              </Heading>
              {data.about_us.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : item.link.indexOf("mailto") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
            </BotListWrapper>
          )}
          {process.env &&
          data.knowledge_center_menu &&
          (process.env.GATSBY_ACTIVE_ENV === "gbp" ||
            (process.env.GATSBY_ACTIVE_ENV === "eur" &&
              intl.locale === "fr")) ? (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.knowledge_center_menu.name}
              </Heading>
              {data.knowledge_center_menu.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
            </BotListWrapper>
          ) : (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.purchases.name}
              </Heading>
              {data.purchases.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
            </BotListWrapper>
          )}
          {process.env &&
          data.shop_menu &&
          (process.env.GATSBY_ACTIVE_ENV === "gbp" ||
            (process.env.GATSBY_ACTIVE_ENV === "eur" &&
              intl.locale === "fr")) ? (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.shop_menu.name}
              </Heading>
              {data.shop_menu.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
            </BotListWrapper>
          ) : (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>{data.help.name}</Heading>
              {data.help.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
            </BotListWrapper>
          )}

          {process.env &&
          data.support_menu &&
          (process.env.GATSBY_ACTIVE_ENV === "gbp" ||
            (process.env.GATSBY_ACTIVE_ENV === "eur" &&
              intl.locale === "fr")) ? (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.support_menu.name}
              </Heading>
              {data.support_menu.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
              {data.support_menu.button && data.support_menu.button.list && (
                <div style={{ marginTop: 10, minWidth: 250 }}>
                  <ButtonContact href={data.support_menu.button.link}>
                    {data.support_menu.button.list}
                  </ButtonContact>
                </div>
              )}
            </BotListWrapper>
          ) : (
            <BotListWrapper>
              <Heading style={{ marginBottom: 15 }}>
                {data.contact.name}
              </Heading>
              {data.contact.list.map((item, index) =>
                item.link && item.link.indexOf("http") == 0 ? (
                  <ListElement
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    href={item.link}
                  >
                    {item.list}
                  </ListElement>
                ) : (
                  <ListElementWrapper
                    key={index.toString()}
                    style={
                      index !== 0
                        ? {
                            borderTopWidth: 1,
                            borderTopColor: "rgba(255, 255, 255, 0.08)",
                            borderTopStyle: "solid",
                          }
                        : {}
                    }
                    target="blank"
                    to={item.link}
                  >
                    {item.list}
                  </ListElementWrapper>
                )
              )}
              {data.contact.button && (
                <div style={{ marginTop: 10, minWidth: 250 }}>
                  <ButtonContact
                    target="_blank"
                    href={data.contact.button.link}
                  >
                    {data.contact.button.list}
                  </ButtonContact>
                </div>
              )}
            </BotListWrapper>
          )}
        </Container>
      </BotDiv>
      {/* <FooterIn>
        {data.footer_bottom.list.length > 0 &&
          data.footer_bottom.list.map((item, index) => (
            <li key={index.toString()}>
              {item.link && item.link.indexOf("http") == 0 ? (
                <ListElement
                  key={index.toString()}
                  style={{
                    fontSize: 10.71,
                  }}
                  href={item.link}
                >
                  {item.list}
                </ListElement>
              ) : (
                <ListElementWrapper
                  key={index.toString()}
                  style={{
                    fontSize: 10.71,
                  }}
                  to={item.link}
                >
                  {item.list}
                </ListElementWrapper>
              )}
            </li>
          ))}
      </FooterIn> */}
      <BackToTop
        onClick={scrollTop}
        // style={{ display: showScroll ? "flex" : "none" }}
      >
        <TopArrow />
      </BackToTop>
    </FooterWrapper>
  )
}

const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const NewsletterTitle = styled.div`
  font-size: ${({ lang }) => (lang === "fr" ? "22px" : "24px")};
  line-height: 1.2;
  text-transform: uppercase;
  font-family: "BebasNeueBold";
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
  padding: 8px 20px;
  border-radius: 20px 0px;
  margin-bottom: 15px;
`

const NewsletterText = styled.div`
  font-family: "Museo";
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-bottom: 0.3em;
  font-weight: bold;

  > span {
    color: #5ebabb;
  }

  p {
    margin: 0px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  img {
    margin: 0px 10px 0px 0px;
    max-height: 15px;
  }

  @media (max-width: 550px) {
    text-align: left;
    font-size: 14px;
  }
`

export default injectIntl(withStoreContext(Footer))
